import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewChecked {

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  userColl: any = [];

  loginform = new FormGroup({
    username: new FormControl('', Validators.required),
    userpwd: new FormControl('', Validators.required),
  });


  constructor(public loginService: LoginDataService, public appService: DhukanDataService,
    private toastr: ToastrService, public router: Router) { }

  ngOnInit() {
    this.fnInitializeFoodSymbolFormGroup();

    //setTimeout(() => this.appService.islogin = false);
    this.loginService.islogin = false;
  }

  ngAfterViewChecked() {
    this.loginService.islogin = true;
  }

  fnInitializeFoodSymbolFormGroup() {
    this.loginform.setValue({
      username: '',
      userpwd: ''
    })
  }
  fnResetValidation() {
    if (this.loginService.fnCheckFornControlNotfound(this.loginform.get('username'))) {
      this.loginform.controls['username'].setErrors(null)
    }
  }

  fnLogin() {
    try {
      this.submitted = true;
      this.successfully = false;
      this.fnResetValidation();

      //Validate
      if (this.loginform.valid) {
        var data = {
          'username': this.loginform.get('username').value,
          'passwd': this.loginform.get('userpwd').value,
        }
        this.appService.getUserLogin(data)
          .subscribe(
            (res) => {
              
              if (res.json().status === 200) {
                this.userColl = res.json().result;
               
                if (this.userColl) {

                  if (this.userColl.length > 0) {

                    console.log(this.userColl[0])
                    if (this.userColl[0].checkstatus==='DONE') {
                      sessionStorage.setItem('sbtoken', JSON.stringify(this.userColl[0].loginuserid));

                      this.loginService.fnGetUserRoleList();
                      this.loginService.islogin=false;
  
                      
                      this.router.navigate(['/']).then(() => {
                        window.location.reload();
                      });
                    }
                    else {
                      this.loginform.controls['username'].setErrors({ 'notfound': true });
                    }
  
                  } else {
                    this.loginform.controls['username'].setErrors({ 'notfound': true });
                  }

                }

              }

            },
            (err) => {
              this.toastr.error('Error', err);
            })
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: "LOGIN",
        refnum: refno,
        logdescr: desc,
        logby: refno,
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
