import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { AppSettings } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class LoginDataService {

  public islogin: boolean = false;

  serviceloginChange: Subject<boolean> = new Subject<boolean>();


  userBO: any = {};
  userView: any = {};

  constructor(private http: Http, public router: Router) { }


  fnpostInputParams(url, params) {

    const headers = new Headers({
      //'Content-Type': "application/JSON",
      'authkey': (sessionStorage.authkey === undefined) ? 1 : JSON.parse(sessionStorage.authkey),
      'sbtoken': (sessionStorage.sbtoken === undefined) ? '' : JSON.parse(sessionStorage.sbtoken)
    });

    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }




  //Get UserRole List
  fnGetUserRoleList() {


    var data = {
      'userid': 0,
    }

    this.fnpostInputParams(AppSettings.getUserByTokenUrl, data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result;


            if (_res.length) {
              console.log(this.userBO)
              if (_res.length > 0) {
                this.userBO = _res[0];
              }
              else {
                this.userBO = {};
              }            
            }


            // if (_res[0].length) {
            //   console.log(this.userBO)
            //   if (_res[0].length > 0) {
            //     this.userBO = _res[0][0];
            //   }
            //   else {
            //     this.userBO = {};
            //   }            
            // }

            this.userView = {
              userBO: this.userBO,
            }


            this.serviceloginChange.next(this.userView);
          }
          else if (res.json().status == 400) {
            this.fnShowErrorMsg('Error' + res.json().err_field);
          }
        },
        (err) => {

          this.fnShowErrorMsg('Error' + err);
        })

  }

  fnCheckLogin() {
    if (sessionStorage.sbtoken === undefined) {
      this.router.navigate(['/Login']);
    }

  }


  fnCheckUser() {

    if (this.userBO.userid) {
      return true;
    }
    // else if(localStorage.adtoken !== undefined){
    //   return true;
    // }
    else {
      return false;
    }

  }
  fnGetModUserId() {
    if (this.userBO.userid) {
      return this.userBO.userid;
    }
    else {
      return 0;
    }
  }

  fnGetModCompanyId() {
    if (this.userBO.companyid) {
      return this.userBO.companyid;
    }
    else {
      return 0;
    }
  }

  fnUserFaildRedirect() {
    if (!this.userBO) {
      this.router.navigate(['/Login']);
    }
  }

  fnCheckAdminUser() {

    if (this.userBO.userid) {
      return this.userBO.isadmin;
    }
    else {
      return 0;
    }
  }

  fnCheckFornControlNotfound(fc) {
    let ischeck = false;
    const validator = fc;

    if (validator.errors) {
      if (validator.errors.notfound) {
        ischeck = true;
      }
    }

    return ischeck;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex);
  }



}
