
export const AppSettings = {
  
  //base_url: 'http://200.1.50.108:8000/',
  //base_url: 'http://13.127.71.165:5800/',
  //base_url : 'https://apismartboss.renoinfo.in/',
  base_url : 'https://apismartboss.bigmart.ae/',

    firebaseConfig : {
        apiKey: "AIzaSyC37cMjGWr1q5d8rm7DVbfDhKP_w0fgt10",
        authDomain: "bigmart-online.firebaseapp.com",
        databaseURL: "https://bigmart-online.firebaseio.com",
        projectId: "bigmart-online",
        storageBucket: "bigmart-online.appspot.com",
        messagingSenderId: "47369179063",
        appId: "1:47369179063:web:259af70f6c99282cd42232",
        measurementId: "G-VJC9FQRK9V"
      },


    insertProductGroupUrl: 'productgroup/insertproductgroup',
    setProductGroupUrl: 'productgroup/setproductgroup',
    checkProductGroupUrl: 'productgroup/checkproductgroup',
    getProductGroupUrl: 'productgroup/getproductgroup',
    deleteProductGroupUrl: 'productgroup/deleteproductgroup',

  //Dashboard
    getDashboardSalesUrl: 'dashboard/getdashboardsales',

    //Login
    getUserLoginUrl: 'user/getuserlogin',
  getUserBranchUrl: 'user/getuserbranch',
    
  getUserByTokenUrl: 'user/getuserbytoken',


}


